const lastValueTimer = {
  name: 'startTrade',
  totalStep: 2,
  createPointFigures: ({ coordinates }) => {
    const startX = coordinates[0].x - 5;
    const startY = 70;
    return [
      {
        type: 'triangleArrowRight',
        attrs: {
          x: startX - 10,
          y: 40,
          width: 8,
          height: 8,
        },
        styles: { color: '#60636D' },
      },
      {
        type: 'text',
        attrs: {
          x: startX,
          y: startY,
          text: 'Beginning of trade',
          align: 'right',
          baseline: 'bottom',
        },
        styles: {
          size: 12,
          backgroundColor: 'transparent',
          color: '#60636D',
        },
        ignoreEvent: true,
      },
    ];
  },
};

export default lastValueTimer;
