import { getCoordinateFromTimestamp } from './segmentTag';

/* eslint-disable no-console */
const SegmentTag = {
  name: 'endTrade',
  totalStep: 2,
  zLevel: 1,
  createPointFigures: ({ overlay, xAxis, bounding }) => {
    let { targetCandleEnd, label } = overlay.extendData ?? '';

    const startX = getCoordinateFromTimestamp(targetCandleEnd, xAxis._ticks);
    const startY = 0;

    const startX2 = startX;
    const startY2 = bounding.height;

    return [
      {
        type: 'line',
        attrs: {
          coordinates: [
            {
              x: startX,
              y: startY,
            },
            {
              x: startX2,
              y: startY2,
            },
          ],
        },
        styles: {
          color: '#60636D',
          borderSize: 3,
        },
        ignoreEvent: true,
      },
      {
        type: 'triangleArrowLeft',
        attrs: {
          x: startX2 + 12,
          y: 40,
          width: 8,
          height: 8,
        },
        styles: { color: '#60636D' },
      },
      {
        type: 'text',
        attrs: {
          x: startX,
          y: 70,
          text: 'End of trade',
          align: 'left',
          baseline: 'bottom',
        },
        styles: {
          size: 12,
          backgroundColor: 'transparent',
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
          paddingBottom: 5,
          color: '#60636D',
        },
        ignoreEvent: true,
      },
      {
        type: 'text',
        attrs: {
          x: startX,
          y: 85,
          text: label,
          align: 'left',
          baseline: 'bottom',
        },
        styles: {
          size: 12,
          backgroundColor: 'transparent',
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
          paddingBottom: 5,
          color: '#60636D',
        },
        ignoreEvent: true,
      },
    ];
  },
};

export default SegmentTag;
