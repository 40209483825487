import {
  dispose,
  init,
  registerFigure,
  registerOverlay,
  TooltipIconPosition,
  registerLocale,
  getSupportedLocales,
} from '../klinechart/build/klinecharts.min.js';
import React, { useContext, useEffect, useState } from 'react';
import positiveTag from '../klinechart/overlay/positiveTag';
import negativeTag from '../klinechart/overlay/negativeTag';
import circle from '../klinechart/figure/circle';
import positiveSegment from '../klinechart/overlay/positiveSegment';
import negativeSegment from '../klinechart/overlay/negativeSegment';
import customArrow from '../klinechart/figure/custom-arrow';
import text from '../klinechart/figure/text';
import SegmentTag from '../klinechart/overlay/segmentTag';
import moment from 'moment';
import { useGetSetData } from '../hooks/useGetSetData';
import lastValueTimer from '../klinechart/overlay/timerTag';
import startTradeLine from '../klinechart/overlay/startTrade';
import endTradeLine from '../klinechart/overlay/endTrade';
import useTimeZone from '../hooks/useTimeZone';
import anyWaves from '../klinechart/overlay/anyWaves';
import arrow from '../klinechart/overlay/arrow';
import circleOverlay from '../klinechart/overlay/circle';
import eightWaves from '../klinechart/overlay/eightWaves';
import fibonacciCircle from '../klinechart/overlay/fibonacciCircle';
import fibonacciExtension from '../klinechart/overlay/fibonacciExtension';
import fibonacciSegment from '../klinechart/overlay/fibonacciSegment';
import fibonacciSpeedResistanceFan from '../klinechart/overlay/fibonacciSpeedResistanceFan';
import fibonacciSpiral from '../klinechart/overlay/fibonacciSpiral';
import fiveWaves from '../klinechart/overlay/fiveWaves';
import gannBox from '../klinechart/overlay/gannBox';
import parallelogram from '../klinechart/overlay/parallelogram';
import rect from '../klinechart/overlay/rect';
import threeWaves from '../klinechart/overlay/threeWaves';
import triangle from '../klinechart/overlay/triangle';
import xabcd from '../klinechart/overlay/xabcd';
import abcd from '../klinechart/overlay/abcd';
import { useChartAction } from '../store/useChartAction';
import { SubscribeActions } from '../klinechart/constant';
import useHandleIndicator from '../hooks/useHandleIndicator';
import { useGlobalStore } from '../store/useGlobalStore';
import { useGetFavForexPairs } from '../api/forexPairApi';
import useResizeWindows from '../hooks/useResizeWindows.js';
import { useUserStore } from '../store/useUserStore.js';
import {
  arLocales,
  bnLocales,
  enLocales,
  esLocales,
  filLocales,
  frLocales,
  hiLocales,
  jaLocales,
  msLocales,
  ptLocales,
  ruLocales,
  thLocales,
  trLocales,
  ukLocales,
  viLocales,
  zhLocales,
} from '../klinechart/i18n/translation.js';
import { useGetProfileDetails } from '../api/profileApi.js';
import { LANGUAGES } from '../constants/language.js';
import { useShowLabelsForActiveTrades } from '../hooks/useSocket.js';
import { triangleArrowRight } from '../klinechart/figure/triangleArrowRight.js';
import useMediaQuery from '../hooks/useMediaQuery.js';
import { triangleArrowLeft } from '../klinechart/figure/triangleArrowLeft.js';
import { getCandleTimestamps, getTimeLeft } from '../utils/utils.js';
import { INVESTMENT_TIME_TYPE } from '../utils/constants.js';

export const ChartContext = React.createContext();

export const useChartContext = () => useContext(ChartContext);

export function ChartProvider({ children }) {
  const isTab = useMediaQuery(769);
  const { data: { data: getProfileData = {} } = {} } = useGetProfileDetails();
  const { data: { data: { data: favList = [] } = {} } = {} } = useGetFavForexPairs();
  const [chartRef, setChartRef] = useState(null);
  const [activeSocketTradePairs, setActiveSocketTradePairs] = useState({});
  const { activeTrades } = useGlobalStore();
  const { chartType = 'candle_solid', timeFrame } = useChartAction();
  const { getForexActivePair } = useGetSetData();
  const { getCurrentTimeZone, utcTime } = useTimeZone();
  const [settingsIndicator, setSettingsIndicator] = useState(null);
  const [selectedOverlay, setSelectedOverlay] = useState(null);
  const { removeChartIndicatorWithIcon } = useHandleIndicator();
  const { theme } = useUserStore();
  const [chartInfo, setChartInfo] = useState({
    pair: null,
    timespan: timeFrame.chartValue,
    lastTime: null,
  });
  useShowLabelsForActiveTrades({
    pair: getForexActivePair?.forexPair?.actualPair,
    timeFrame: timeFrame.type,
    getCurrentTimeZone,
    activeTrades,
  });

  const registerLanguages = () => {
    const locales = [
      { name: 'ar', data: arLocales },
      { name: 'bn', data: bnLocales },
      { name: 'en', data: enLocales },
      { name: 'es', data: esLocales },
      { name: 'fil', data: filLocales },
      { name: 'fr', data: frLocales },
      { name: 'hi', data: hiLocales },
      { name: 'ja', data: jaLocales },
      { name: 'ms', data: msLocales },
      { name: 'pt', data: ptLocales },
      { name: 'ru', data: ruLocales },
      { name: 'th', data: thLocales },
      { name: 'tr', data: trLocales },
      { name: 'uk', data: ukLocales },
      { name: 'vi', data: viLocales },
      { name: 'zh', data: zhLocales },
    ]; // List of target languages
    locales.forEach((lang) => {
      registerLocale(lang.name, lang.data);
    });
  };

  const initiate = () => {
    const ref = init('chart', {
      styles: {
        grid: {
          show: true,
          horizontal: {
            style: 'line',
            color: theme !== 'dark' ? '#c3c4ca' : '#2D3343',
          },
          vertical: {
            style: 'line',
            color: theme !== 'dark' ? '#c3c4ca' : '#2D3343',
          },
        },
        xAxis: {
          axisLine: {
            show: false,
          },
          tickLine: {
            show: false,
          },
          tickText: {
            color: theme !== 'dark' ? '#2D3343' : '#fff',
          },
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          tickLine: {
            show: false,
          },
          tickText: {
            color: theme !== 'dark' ? '#2D3343' : '#fff',
          },
        },
        indicator: {
          tooltip: {
            showType: 'standard',
            text: {
              size: 12,
              family: 'Helvetica Neue',
              weight: 'normal',
              color: '#D9D9D9',
            },
            icons: [
              {
                id: 'setting',
                position: TooltipIconPosition.Middle,
                marginLeft: 6,
                marginTop: 7,
                marginBottom: 0,
                marginRight: 0,
                paddingLeft: 0,
                paddingTop: 0,
                paddingRight: 0,
                paddingBottom: 0,
                icon: '\ue902',
                fontFamily: 'icomoon',
                size: 14,
                color: '#6d7499',
                backgroundColor: 'transparent',
                activeColor: '#6d7499',
              },
              {
                id: 'close',
                position: TooltipIconPosition.Middle,
                marginLeft: 6,
                marginTop: 7,
                marginRight: 0,
                marginBottom: 0,
                paddingLeft: 0,
                paddingTop: 0,
                paddingRight: 0,
                paddingBottom: 0,
                icon: '\ue900',
                fontFamily: 'icomoon',
                size: 14,
                color: '#6d7499',
                backgroundColor: 'transparent',
                activeColor: '#6d7499',
              },
            ],
          },
        },
        crosshair: {
          horizontal: {
            line: {
              style: 'solid',
            },
            color: theme !== 'dark' ? '#c3c4ca' : '#2D3343',
          },
          vertical: {
            line: {
              style: 'solid',
            },
            color: theme !== 'dark' ? '#c3c4ca' : '#2D3343',
          },
        },
        candle: {
          type: chartType,
          priceMark: {
            last: {
              upColor: theme !== 'dark' ? '#2D3343' : '#fff',
              downColor: theme !== 'dark' ? '#2D3343' : '#fff',
              noChangeColor: theme !== 'dark' ? '#2D3343' : '#fff',
              text: {
                color: theme !== 'dark' ? '#fff' : '#2D3343',
              },
            },
          },
          bar: {
            upColor: '#0FAF59',
            downColor: '#FF6251',
            upBorderColor: '#0FAF59',
            downBorderColor: '#FF6251',
            upWickColor: '#0FAF59',
            downWickColor: '#FF6251',
          },
          tooltip: {
            showType: 'standard',
            rect: {
              // 'fixed' | 'pointer'
              position: 'fixed',
              paddingLeft: 0,
              //  paddingRight: 0,
              paddingTop: 0,
              //  paddingBottom: 6,
              offsetLeft: 0,
              offsetTop: 8,
              offsetRight: 0,
              offsetBottom: 8,
              borderRadius: 4,
              borderSize: 0,
              borderColor: 'transparent',
              color: 'transparent',
            },
            custom: () => {
              return [
                { title: 'Timezone', value: `${utcTime}    | ` },
                { title: 'open', value: '{open}' },
                { title: 'close', value: '{close}' },
                { title: 'high', value: '{high}' },
                { title: 'low', value: '{low}' },
              ];
            },
          },
        },
      },
    });
    registerFigure(circle);
    registerFigure(customArrow);
    registerFigure(text);
    registerFigure(triangleArrowRight);
    registerFigure(triangleArrowLeft);

    registerOverlay(positiveSegment);
    registerOverlay(negativeSegment);
    registerOverlay(SegmentTag);
    registerOverlay(positiveTag);
    registerOverlay(negativeTag);
    registerOverlay(lastValueTimer);
    registerOverlay(endTradeLine);
    registerOverlay(startTradeLine);

    registerOverlay(anyWaves);
    registerOverlay(threeWaves);
    registerOverlay(fiveWaves);
    registerOverlay(eightWaves);
    registerOverlay(arrow);
    registerOverlay(circleOverlay);
    registerOverlay(rect);
    registerOverlay(triangle);
    registerOverlay(parallelogram);
    registerOverlay(fibonacciCircle);
    registerOverlay(fibonacciExtension);
    registerOverlay(fibonacciSegment);
    registerOverlay(fibonacciSpeedResistanceFan);
    registerOverlay(fibonacciSpiral);
    registerOverlay(gannBox);
    registerOverlay(xabcd);
    registerOverlay(abcd);
    registerLanguages();

    setChartRef(ref);
    ref.subscribeAction(SubscribeActions.onTooltipIconClick, (e) => {
      switch (e.iconId) {
        case 'close': {
          removeChartIndicatorWithIcon({ indicatorId: e.paneId, name: e.indicatorName });
          break;
        }
        case 'setting': {
          const indicator = ref?.getIndicatorByPaneId(e.paneId, e.indicatorName);
          let payload = {
            id: e.paneId === 'candle_pane' ? `main${e.indicatorName}` : `sub${e.indicatorName}`,
            paneId: e.paneId,
            name: e.indicatorName,
            calcParams: indicator.calcParams,
          };
          window?.Android?.indicatorSettingsClicked(JSON.stringify(payload));
          setSettingsIndicator(payload);

          break;
        }
        default: {
          break;
        }
      }
    });
    ref.setTimezone(getCurrentTimeZone);
    if (isTab) {
      ref.setMaxOffsetRightDistance(100);
      ref.setOffsetRightDistance(100);
    } else {
      ref.setMaxOffsetRightDistance(500);
      ref.setOffsetRightDistance(500);
    }
    ref.loadMore((cb) => {
      setChartInfo((c) => ({ ...c, lastTime: cb }));
    });
    return ref;
  };

  useEffect(() => {
    window?.chartRef?.setLocale(LANGUAGES[getProfileData?.language]);
  }, [getProfileData?.language, getSupportedLocales()]);

  useEffect(() => {
    if (window?.chartRef) {
      window.chartRef.setStyles({
        grid: {
          horizontal: {
            color: theme !== 'dark' ? '#c3c4ca' : '#2D3343',
          },
          vertical: {
            color: theme !== 'dark' ? '#c3c4ca' : '#2D3343',
          },
        },
        xAxis: {
          tickText: {
            color: theme !== 'dark' ? '#2D3343' : '#fff',
          },
        },
        yAxis: {
          tickText: {
            color: theme !== 'dark' ? '#2D3343' : '#fff',
          },
        },
        candle: {
          priceMark: {
            last: {
              upColor: theme !== 'dark' ? '#2D3343' : '#fff',
              downColor: theme !== 'dark' ? '#2D3343' : '#fff',
              noChangeColor: theme !== 'dark' ? '#2D3343' : '#fff',
              text: {
                color: theme !== 'dark' ? '#fff' : '#2D3343',
              },
            },
          },
        },
      });
    }
  }, [theme]);

  useEffect(() => {
    if (window.chartRef) {
      window.chartRef.setTimezone(getCurrentTimeZone);
      window.chartRef.setStyles({
        candle: {
          tooltip: {
            custom: () => {
              return [
                { title: 'Timezone', value: `${utcTime}     |` },
                { title: 'open', value: '{open}' },
                { title: 'close', value: '{close}' },
                { title: 'high', value: '{high}' },
                { title: 'low', value: '{low}' },
              ];
            },
          },
        },
      });
    }
  }, [getCurrentTimeZone]);

  const windowResize = useResizeWindows();

  useEffect(() => {
    if (window?.chartRef) {
      if (isTab) {
        window.chartRef.setMaxOffsetRightDistance(100);
        window.chartRef.setOffsetRightDistance(100);
      } else {
        window.chartRef.setMaxOffsetRightDistance(500);
        window.chartRef.setOffsetRightDistance(500);
      }
      window.chartRef.resize();
    }
  }, [windowResize]);

  const handleTimer = (type = 'create', value, lastCandleStamp, currentCloseStamp) => {
    const nextCandleTimeStamp = lastCandleStamp;
    const diff = nextCandleTimeStamp - currentCloseStamp;

    if (type === 'create') {
      window.chartRef.createOverlay({
        id: 'timer',
        name: 'timer',
        points: [{ value: value, timestamp: Date.now() }],
        visible: false,
      });
    } else {
      window.chartRef.overrideOverlay({
        id: 'timer',
        name: 'timer',
        points: [
          {
            value: value,
            timestamp: Date.now(),
          },
        ],
        extendData: moment().startOf('day').milliseconds(diff).format('HH:mm:ss'),
        visible: true,
      });
    }
  };

  const disposeChart = () => {
    dispose('chart');
  };

  const handleStartEndTradeLine = ({ value, investmentCurrentTime, investmentTimeType }) => {
    const candleRange = getCandleTimestamps(new Date(), timeFrame?.type);
    const candleRangeOfCurrentTime = getCandleTimestamps(investmentCurrentTime, timeFrame?.type);
    if (
      investmentTimeType === INVESTMENT_TIME_TYPE.CURRENT &&
      window.chartRef.getOverlayById('startTrade') &&
      window.chartRef.getOverlayById('endTrade')
    ) {
      window.chartRef.overrideOverlay({
        id: 'startTrade',
        name: 'startTrade',
        points: [
          {
            value: value,
            timestamp: candleRange?.candleStart,
          },
        ],
        visible: true,
      });
      window.chartRef.overrideOverlay({
        id: 'endTrade',
        name: 'endTrade',
        points: [{ value: value, timestamp: candleRangeOfCurrentTime?.candleStart }],
        visible: true,
        extendData: {
          label: `${getTimeLeft(investmentCurrentTime + 1000)}`,
          targetCandleEnd: candleRangeOfCurrentTime?.candleEnd,
        },
      });
    } else if (investmentTimeType === INVESTMENT_TIME_TYPE.CURRENT) {
      window.chartRef.createOverlay({
        id: 'startTrade',
        name: 'startTrade',
        points: [{ value: value, timestamp: candleRange?.candleStart }],
        visible: false,
      });
      window.chartRef.createOverlay({
        id: 'endTrade',
        name: 'endTrade',
        points: [{ value: value, timestamp: candleRangeOfCurrentTime?.candleEnd }],
        visible: false,
        extendData: {
          label: `${getTimeLeft(investmentCurrentTime + 1000)}`,
          targetCandleEnd: candleRangeOfCurrentTime?.candleEnd,
        },
      });
    } else if (investmentTimeType === INVESTMENT_TIME_TYPE.NORMAL) {
      window.chartRef.removeOverlay('startTrade');
      window.chartRef.removeOverlay('endTrade');
    }
  };

  return (
    <ChartContext.Provider
      value={{
        chartRef: chartRef,
        initiate,
        disposeChart,
        chartInfo,
        setChartInfo,
        timeFrame,
        handleTimer,
        setSettingsIndicator,
        settingsIndicator,
        setSelectedOverlay,
        selectedOverlay,
        favList,
        activeSocketTradePairs,
        setActiveSocketTradePairs,
        handleStartEndTradeLine,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
}
