import styled from 'styled-components';

export const ScaleContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  z-index: 2;

  .ant-btn-default {
    opacity: 0.9;
    padding: 0px 7px;
    height: 24px;
  }

  .ant-btn-default:hover {
    opacity: 1;
    color: var(--color-text);
  }
`;
