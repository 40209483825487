export const getCoordinateFromTimestamp = (targetTime, ticks) => {
  if (ticks.length > 1) {
    const firstTick = ticks[0];
    const lastTick = ticks[ticks.length - 1];

    // If targetTime is before the first tick, return the first tick's coordinate
    if (targetTime < firstTick.value) {
      return firstTick.coord;
    }

    // If targetTime is after the last tick, extrapolate or return the last tick's coordinate
    if (targetTime > lastTick.value) {
      // Extrapolate based on the difference between the last two ticks
      const secondLastTick = ticks[ticks.length - 2];
      const timeDiff = lastTick.value - secondLastTick.value;
      const coordDiff = lastTick.coord - secondLastTick.coord;

      // Calculate how far beyond the last tick's coordinate the targetTime would fall
      const extraTime = targetTime - lastTick.value;
      const extraCoord = (extraTime / timeDiff) * coordDiff;

      return lastTick.coord + extraCoord;
    }

    // If targetTime is within the range of ticks, interpolate between the ticks
    for (let i = 0; i < ticks.length - 1; i++) {
      const tick1 = ticks[i];
      const tick2 = ticks[i + 1];
      if (targetTime >= tick1.value && targetTime <= tick2.value) {
        return tick1.coord + ((targetTime - tick1.value) / (tick2.value - tick1.value)) * (tick2.coord - tick1.coord);
      }
    }
  }

  return null; // This should not happen if all cases are handled
};

/* eslint-disable no-console */
const SegmentTag = {
  name: 'SegmentTag',
  totalStep: 2,
  needDefaultPointFigure: true,
  needDefaultXAxisFigure: true,
  needDefaultYAxisFigure: true,
  zLevel: 1,
  createPointFigures: ({ overlay, coordinates, xAxis }) => {
    let { label, activeTradeIndex, type, targetCandleEnd } = overlay.extendData ?? '';

    const startX = coordinates[0].x - (140 * activeTradeIndex + 20);
    const startY = coordinates[0].y;

    const startX1 = coordinates[0].x;
    const startY1 = coordinates[0].y;

    const startX2 = getCoordinateFromTimestamp(targetCandleEnd, xAxis._ticks);
    const startY2 = coordinates[0].y;

    return [
      {
        type: 'line',
        attrs: {
          coordinates: [
            {
              x: startX,
              y: startY,
            },
            {
              x: startX1,
              y: startY1,
            },
          ],
        },
        styles: {
          color: type === 'Long' ? 'green' : 'red',
          borderSize: 3,
        },
        ignoreEvent: true,
      },
      {
        type: 'line',
        attrs: {
          coordinates: [
            {
              x: startX1,
              y: startY1,
            },
            {
              x: startX2,
              y: startY2,
            },
          ],
        },
        styles: {
          color: type === 'Long' ? 'green' : 'red',
          borderSize: 3,
        },
        ignoreEvent: true,
      },
      {
        type: 'custom-circle',
        attrs: {
          x: startX1,
          y: startY1,
        },
        styles: {
          style: 'stroke-fill',
          borderColor: type === 'Long' ? 'green' : 'red',
        },
        ignoreEvent: true,
      },
      {
        type: 'custom-circle',
        attrs: {
          x: startX2,
          y: startY2,
        },
        styles: {
          style: 'stroke-fill',
          borderColor: type === 'Long' ? 'green' : 'red',
        },
        ignoreEvent: true,
      },
      {
        type: 'custom-text',
        attrs: {
          x: startX,
          y: startY + 10,
          text: label ?? '',
          align: 'right',
          baseline: 'bottom',
        },
        styles: {
          borderRadius: 10,
          paddingLeft: 30,
          type: type,
        },
        ignoreEvent: true,
      },
    ];
  },
};

export default SegmentTag;
