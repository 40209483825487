import { useEffect, useState } from 'react';
import { useChartContext } from '../../../../context/ChartContext';
import useReactQuerySubscription from '../../../../hooks/useSocket';
import { useForexHistory } from '../../../../api/forexPairApi';
import { useGetSetData } from '../../../../hooks/useGetSetData';
import { FOREX_PAIR_TYPE, INVESTMENT_TIME_TYPE, TIME_SPAN, handleAddCandle } from '../../../../utils/constants';
import { useTrade } from '../../../../store/useTrade';
import { countDecimal } from '../../../../utils/utils';
import { Spin } from 'antd';
import useTimeZone from '../../../../hooks/useTimeZone';
import OverlayToolMenu from '../OverlayToolMenu';
import useHandleOverlay from '../../../../hooks/useHandleOverlay';
import useHandleIndicator from '../../../../hooks/useHandleIndicator';
import { useChartAction } from '../../../../store/useChartAction';
import { useSocketContext } from '../../../../context/SocketContext';
import { useGlobalStore } from '../../../../store/useGlobalStore';
import WorkerFactory from '../../../../webworkers/WorkerFactory';
import DetectWakeUp from '../../../../webworkers/DetectWakeUp';
import { hideAllPopovers } from '../../../../helpers/popover';
import ZoomInOut from '../ZoomInOut';

const TradeChart = () => {
  const [chartLoader, setChartLoader] = useState(false);
  const { isConnected, setIsConnected } = useSocketContext();
  const { getActivePairId, getForexActivePair } = useGetSetData();
  const {
    initiate,
    chartInfo,
    setChartInfo,
    disposeChart,
    handleTimer,
    setSelectedOverlay,
    setActiveSocketTradePairs,
    handleStartEndTradeLine,
  } = useChartContext();
  const { timeFrame, indicators, overlays } = useChartAction();
  const { getCurrentTimeZone, resetCurrentTradeTime, updateCurrentTradeTime } = useTimeZone();
  const { investmentTimeType, investmentTime, setInvestmentTime, trades } = useTrade();

  const investmentCurrentTime = investmentTime * 1000;

  const { socket } = useReactQuerySubscription(
    {
      pair: getForexActivePair?.forexPair?.actualPair,
      timeFrame: timeFrame.type,
      getCurrentTimeZone,
      investmentTimeType,
    },
    (pair, newData) => {
      setActiveSocketTradePairs((activeTrade) => ({ ...activeTrade, [newData?.pair]: newData }));
      if (pair === newData?.pair) {
        const chartData = window.chartRef.getDataList();

        const lastCandle = chartData.at(-1);
        if (lastCandle) {
          if (newData.end > lastCandle.timestamp) {
            let payload = {
              open: lastCandle?.close,
              close: newData?.close,
              high: newData?.high,
              low: newData?.low,
              timestamp: handleAddCandle(lastCandle.timestamp, newData.end, timeFrame.type),
            };

            if (investmentTimeType === INVESTMENT_TIME_TYPE.CURRENT && getCurrentTimeZone) {
              updateCurrentTradeTime();
            }

            window.chartRef.updateData(payload, (animatedData) => {
              handleTimer(
                'update',
                animatedData.close,
                handleAddCandle(lastCandle.timestamp, animatedData.end, timeFrame.type),
                newData.end,
                investmentCurrentTime,
                investmentTimeType,
              );
              handleStartEndTradeLine({ value: animatedData.close, investmentCurrentTime, investmentTimeType });
            });
          } else if (newData.end >= lastCandle.timestamp - timeFrame.type) {
            let payload = {
              open: lastCandle.open,
              close: newData.close,
              high: lastCandle.high < newData.high ? newData.high : lastCandle.high,
              low: lastCandle.low > newData.low ? newData.low : lastCandle.low,
              timestamp: lastCandle.timestamp,
            };
            window.chartRef.updateData(payload, (animatedData) => {
              if (newData.end === lastCandle.timestamp) {
                payload = {
                  open: newData.close,
                  close: newData.close,
                  high: newData.close,
                  low: newData.close,
                  timestamp: lastCandle.timestamp + timeFrame.type,
                };

                setTimeout(() => {
                  window.chartRef.updateData(payload);
                  handleTimer('update', newData.close, lastCandle.timestamp + timeFrame.type, newData.end);
                  handleStartEndTradeLine({ value: animatedData.close, investmentCurrentTime, investmentTimeType });
                }, 275);
              } else {
                handleTimer(
                  'update',
                  animatedData.close,
                  lastCandle.timestamp + timeFrame.type,
                  newData.end + timeFrame.type,
                );
                handleStartEndTradeLine({ value: animatedData.close, investmentCurrentTime, investmentTimeType });
              }
            });

            if (investmentTimeType === INVESTMENT_TIME_TYPE.CURRENT && getCurrentTimeZone) {
              updateCurrentTradeTime();
            }
          }
        }
      }
    },
  );

  const { setActiveTrades } = useGlobalStore();

  const { createToolOverlay } = useHandleOverlay();
  const { createChartIndicator } = useHandleIndicator();

  const { mutate, mutateAsync } = useForexHistory();

  useEffect(() => {
    if (getActivePairId && isConnected) {
      setChartInfo((c) => ({ ...c, lastTime: null }));
      setChartLoader(true);
      window.chartRef = initiate();
      getForexHistoryData();
      setSelectedOverlay(null);

      if (
        investmentTimeType === INVESTMENT_TIME_TYPE.NORMAL &&
        investmentTime < TIME_SPAN[4].value &&
        getForexActivePair?.forexPair?.type === FOREX_PAIR_TYPE.REGULAR
      ) {
        setInvestmentTime(TIME_SPAN[4].value);
      }
    }

    return () => {
      disposeChart();
    };
  }, [getActivePairId, timeFrame.chartValue, isConnected]);

  const getForexHistoryData = async () => {
    const payload = {
      pairId: getActivePairId,
      timespan: timeFrame?.chartValue,
      lastTime: null,
      limit: 200,
    };
    try {
      const { data: { data: history = [] } = {} } = await mutateAsync(payload);

      if (history.length > 0) {
        let firstItem = history[0];
        let noOfDecimal = countDecimal(firstItem);
        window.chartRef.setPriceVolumePrecision(noOfDecimal);

        window.chartRef.applyMoreData(history.reverse(), true, () => {
          setChartLoader(false);

          window?.Android?.chartLoaded();

          indicators.forEach((indicator) => {
            createChartIndicator({ id: indicator.id, name: indicator.name, noOfDecimal, settings: indicator.settings });
          });

          overlays.forEach((overlay) => {
            if (overlay.pairId === getActivePairId) {
              createToolOverlay({ id: overlay.id, name: overlay.name, points: overlay.points, getActivePairId });
            }
          });

          socket.emit('subscribe', {
            pair: getForexActivePair?.forexPair?.actualPair,
          });

          handleTimer(
            'create',
            history.at(-1).close,
            history.at(-1).timestamp + timeFrame.type,
            history.at(-1).timestamp + timeFrame.type,
          );
          handleStartEndTradeLine({ value: history.at(-1).close, investmentCurrentTime, investmentTimeType });
          setActiveTrades(trades?.[getActivePairId] || []);
        });

        window.chartRef.zoomAtTimestamp(10, firstItem.timestamp, 0);
      }

      //reset current mode type to current time value according to timezone
      if (investmentTimeType === INVESTMENT_TIME_TYPE.CURRENT && getCurrentTimeZone) {
        resetCurrentTradeTime();
      }
    } catch {}
  };

  useEffect(() => {
    if (chartInfo?.lastTime) {
      const payload = {
        pairId: getActivePairId,
        timespan: timeFrame?.chartValue,
        lastTime: chartInfo?.lastTime,
      };
      mutate(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartInfo?.lastTime]);

  useEffect(() => {
    const workerInstance = new WorkerFactory(DetectWakeUp);

    workerInstance.onmessage = function (ev) {
      if (ev && ev.data === 'wakeup') {
        setIsConnected(false);
        window.location.reload();
      }
    };
    // clear lastTime due to api call happening when component renders
    return () => {
      setChartInfo((c) => ({ ...c, lastTime: null }));
    };
  }, []);

  return (
    <div
      id="chart"
      onTouchStart={(e) => {
        hideAllPopovers();
      }}
    >
      {' '}
      <OverlayToolMenu />
      <ZoomInOut />
      {chartLoader && <Spin className="chart__spinner" />}
    </div>
  );
};

export default TradeChart;
