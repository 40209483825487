import React, { useState } from 'react';
import { ReactComponent as LogoSVGMob } from '../../../assets/icons/result.svg';
import { ReactComponent as LogoSVG } from '../../../assets/icons/logo-white font.svg';
import { ReactComponent as LogoBlackSVG } from '../../../assets/icons/logo-black font.svg';
import NavigationLink from '../../UI/NavigationLink/NavigationLink';
import './style.scss';
import ArrowSVG from '../../UI/SVG-Icons/ArrowSVG';
import routes from '../../../utils/routes';
import PlusSVG from '../../UI/SVG-Icons/PlusSVG';
import BurgerMenuSVG from '../../UI/SVG-Icons/SidebarSVG/BurgerMenuSVG';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { Badge, Button, Col, Drawer, Popover, Radio, Row } from 'antd';
import Sidebar from '../../Sidebar/Sidebar';
import SecondaryButton from '../../UI/SecondaryButton';
import { BellOutlined, MoreOutlined, RedoOutlined } from '@ant-design/icons';
import { AccountUserPopup, LevelCard, LevelModal, LevelsContainer, UserMenu } from './style';
import { NavLink, useLocation } from 'react-router-dom';
import { useLogout } from '../../../api/authApi';
import { useUserStore } from '../../../store/useUserStore';
import useModal from '../../../hooks/useModal';
import ChangeCurrency from '../../ChangeCurrency';
import NotificationModal from '../../Notification';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useGetTradeAccountDetails, useResetDemoBalance, useResetLiveBalance } from '../../../api/tradeApi';
import standard from '../../../assets/icons/StandardIcon.svg';
import achiever from '../../../assets/icons/AchieverIcon.svg';
import diamond from '../../../assets/icons/DaimondIcon.svg';
import { ACCOUNT_TYPE, LEVELS } from '../../../constants/utils';
import { useNavigate } from 'react-router-dom';
import { formatNumericValues } from '../../../utils/utils';
import _ from 'lodash';

const PrivateNavbar = () => {
  const isTab = useMediaQuery(769);
  const logout = useLogout();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userDetails = useUserStore();
  const queryClient = useQueryClient();
  const { data: profileDetails } = queryClient.getQueryData(['profile', 'details']) || {};

  const { data: { data: { data: tradingAccounts = [] } = {} } = {} } = useGetTradeAccountDetails();
  const { isShowing: isChangeCurrency, toggle: setChangeCurrency } = useModal(false);
  const { isShowing: isNotificationOpen, toggle: setIsNotificationOpen } = useModal(false);
  const { mutate: resetBalance } = useResetDemoBalance();
  const { mutate: resetLiveBalance } = useResetLiveBalance();

  const activeTradingAccount = tradingAccounts.find((acc) => acc.isActive);
  const liveTradingAccount = tradingAccounts.find((acc) => acc.type === 'Live');

  const [isSideBarOpen, setIsSidebarOpen] = useState(false);
  const [isLevelModalOpen, setIsLevelModalOpen] = useState(false);

  const { theme } = useUserStore();

  const { pathname } = useLocation();

  const logoutHandler = () => {
    logout.mutate({});
  };

  const handleSwitchTradingAccount = (type) => {
    if (type === ACCOUNT_TYPE.LIVE) {
      navigate(routes.TERMINAL.HOME);
    } else if (type === ACCOUNT_TYPE.DEMO) {
      navigate(routes.DEMO_TRADE);
    }
  };

  const MoreContent = (
    <>
      <NavigationLink to={!profileDetails?.sponsored && routes.PROFILE.HOME + routes.PROFILE.DEPOSIT}>
        <Button type="primary" icon={<PlusSVG />} block>
          {t('Deposit')}
        </Button>
      </NavigationLink>
      <NavigationLink to={routes.PROFILE.HOME + routes.PROFILE.WITHDRAWAL}>
        <SecondaryButton>{t('Withdrawal')}</SecondaryButton>
      </NavigationLink>
    </>
  );

  const getLevelIcon = (level) => {
    const updateLevel = _.upperCase(level);

    if (updateLevel === LEVELS.Standard) {
      return standard;
    }
    if (updateLevel === LEVELS.Premium) {
      return achiever;
    }
    if (updateLevel === LEVELS.Vip) {
      return diamond;
    }
    return standard;
  };

  const levels = [
    {
      icon: standard,
      title: t(LEVELS.Standard),
      description: t('Enjoy the first level with minimal balance!'),
    },
    {
      icon: achiever,
      title: t(LEVELS.Premium),
      description: (
        <>
          {t('Balance from $1,000.00')} <br /> {t('Enables fast withdrawal.')}
        </>
      ),
    },
    {
      icon: diamond,
      title: t(LEVELS.Vip),
      description: (
        <>
          {t('Balance from $3,000.00')} <br /> {t('Enables fastest withdrawal.')}
        </>
      ),
    },
  ];

  const UserAccount = (
    <AccountUserPopup>
      <Row className="details__container">
        <Col xs={24} sm={16}>
          <Row>
            <Col span={24}>
              <div className="personal__details">
                <h4>{userDetails?.user?.email}</h4>
              </div>
            </Col>
            {tradingAccounts
              .sort((a, b) => b.type.localeCompare(a.type))
              .map((account, index) => (
                <Col span={24} key={`account_${index}`} className="trading__account">
                  <Radio onClick={() => handleSwitchTradingAccount(account.type)} checked={account.isActive}>
                    {' '}
                    <h4>{t(`${account.type} Account`)}</h4>{' '}
                    <h4>{`${formatNumericValues(+account.balance || 0)} ${account.currency}`}</h4>{' '}
                  </Radio>
                  {((account.type === 'Live' && !!profileDetails?.sponsored) || account.type === 'Demo') && (
                    <div
                      className="refresh-balance"
                      onClick={() => (account.type === 'Live' ? resetLiveBalance({}) : resetBalance({}))}
                    >
                      <RedoOutlined />
                    </div>
                  )}
                </Col>
              ))}
            <Col span={24} className="change__currency">
              <img src={profileDetails?.currencyFlag} alt="Currency Country" />
              {t('Currency')}: {profileDetails?.currency}
              <Button type="text" onClick={setChangeCurrency}>
                {t('Change')}
              </Button>
            </Col>
            <Col>
              <div className="profit__container" onClick={() => setIsLevelModalOpen(true)}>
                <div className="icon__wrapper">
                  <img alt="level" src={getLevelIcon(liveTradingAccount?.level)} />
                </div>
                <div>
                  <h4>{t(LEVELS[liveTradingAccount?.level])}</h4>
                  {/* <h4>{t('X_Profit', { profit: '100' })}</h4> */}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={8}>
          <div className="profile__tabs">
            <NavLink to={routes.PROFILE.HOME + routes.PROFILE.DEPOSIT}>{t('Deposit')}</NavLink>
            <NavLink to={routes.PROFILE.HOME + routes.PROFILE.WITHDRAWAL}>{t('Withdrawal')}</NavLink>
            <NavLink to={routes.PROFILE.HOME + routes.PROFILE.TRANSACTION}>{t('Transaction')}</NavLink>
            <NavLink to={routes.PROFILE.HOME + routes.PROFILE.TRADES}>{t('Trades')}</NavLink>
            <NavLink to={routes.PROFILE.HOME + routes.PROFILE.ACCOUNT}>{t('Account')}</NavLink>
            <Button type="text" onClick={logoutHandler} loading={logout.isPending} style={{ display: 'block' }}>
              {t('Logout')}
            </Button>
          </div>
        </Col>
      </Row>
    </AccountUserPopup>
  );

  return (
    <header className="header">
      {isTab && (
        <Drawer open={isSideBarOpen} placement="left" width={50}>
          <Sidebar setIsSidebarOpen={setIsSidebarOpen} />
        </Drawer>
      )}
      <div className="header__brand">
        {isTab && (
          <div onClick={() => setIsSidebarOpen(true)}>
            <BurgerMenuSVG />
          </div>
        )}
        <NavigationLink to={routes.TERMINAL}>
          {isTab ? (
            <LogoSVGMob />
          ) : theme === 'light' ? (
            <LogoBlackSVG height={40} width={200} />
          ) : (
            <LogoSVG height={40} width={200} />
          )}
        </NavigationLink>
      </div>
      <div className="header__container">
        <div className="header__usermenu">
          {isNotificationOpen && <NotificationModal isOpen={isNotificationOpen} setIsOpen={setIsNotificationOpen} />}
          <Badge count={profileDetails?.unreadNotifications}>
            <SecondaryButton
              size={isTab ? 'small' : 'medium'}
              className="notification__icon"
              icon={<BellOutlined />}
              onClick={setIsNotificationOpen}
            />
          </Badge>
          <Popover
            trigger="hover"
            key={pathname}
            content={UserAccount}
            arrow={false}
            placement="bottomRight"
            rootClassName="user__account"
            zIndex={99}
          >
            <UserMenu>
              <img alt="level" src={getLevelIcon(liveTradingAccount?.level)} />
              <div className="text">
                <div className="name text-wrap">
                  {t(`${activeTradingAccount?.type || 'Demo'}${isTab ? '' : ' Account'}`)}
                </div>
                <div className="balance">{`${formatNumericValues(+activeTradingAccount?.balance || 0)} ${
                  activeTradingAccount?.currency || ''
                }`}</div>
              </div>
              <ArrowSVG />
            </UserMenu>
          </Popover>
          {isChangeCurrency && <ChangeCurrency isOpen={isChangeCurrency} setIsOpen={setChangeCurrency} />}
        </div>
        <div className="header__navlinks">
          {MoreContent}
          <Popover
            rootClassName="deposit_withdrawal"
            placement="bottomRight"
            content={MoreContent}
            arrow={false}
            trigger="click"
          >
            <SecondaryButton size="small" className="moreButton" icon={<MoreOutlined />} />
          </Popover>
        </div>
      </div>
      {isLevelModalOpen && (
        <LevelModal title={t('Account Levels')} open={true} onCancel={() => setIsLevelModalOpen(false)} width={400}>
          <LevelsContainer>
            {levels?.map((item) => {
              return (
                <LevelCard className={item.title === liveTradingAccount?.level ? 'active_card' : ''}>
                  <div className="card_row">
                    <div>
                      <img className="levels__icon" src={item?.icon} alt={item?.title} />
                    </div>
                    <div>
                      <div className="title">{item?.title}</div>
                      <div className="description">{item?.description}</div>
                    </div>
                  </div>
                  <Badge className="active_badge">Active</Badge>
                </LevelCard>
              );
            })}
          </LevelsContainer>
        </LevelModal>
      )}
    </header>
  );
};

export default PrivateNavbar;
