import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ConfigProvider } from 'antd';
import './index.scss';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { I18nextProvider } from 'react-i18next';
import SocketProvider from './context/SocketContext';
import i18n from './i18n';
import { Helmet } from 'react-helmet';
import logo from './assets/images/logo.svg';

// Create a client
const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error) => {
      if (error?.response?.status === 503) {
        throw new Response('Under Maintenance', { status: 503 });
      }
    },
  }),
  queryCache: new QueryCache({
    onError: (error) => {
      if (error?.response?.status === 503) {
        throw new Response('Under Maintenance', { status: 503 });
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18n}>
    <Helmet>
      <title>MartexTrade</title>
      <meta property="og:title" content="MartexTrade" />
      <meta property="og:description" content="World’s biggest binary trading platform!" />
      <meta name="description" content="World’s biggest binary trading platform!" />
      <meta property="og:image" content={'https://cdn.martextrade.com/mt-logo/new-icon1.png'} />
      <meta property="og:url" content={process.env.REACT_APP_HOMEPAGE} />
      <meta name="twitter:title" content="MartexTrade" />
      <meta name="twitter:description" content="World’s biggest binary trading platform!" />
      <meta name="twitter:image" content={logo} />
      {process.env.REACT_APP_ENV !== 'production' && <meta name="robots" content="noindex" />}
    </Helmet>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorText: `var(--color-text)`,
            colorPrimaryBorder: `var(--color-grey-6)`,
            colorPrimaryBg: `var(--color-success)`,
            colorIcon: `var(--color-text)`,
            colorPrimary: `var(--color-success)`,
            controlItemBgHover: `var(--color-grey-6)`,
          },
          components: {
            Button: {
              controlHeightSM: '30px',
              colorBgTextHover: `var(--color-text)`,
              colorBgContainer: `var(--color-grey-4)`,
              defaultBorderColor: `var(--color-grey-4)`,
              colorPrimaryBorder: 'var(--color-success)',
              colorPrimaryActive: `var(--color-success-dark)`,
              colorPrimaryHover: `var(--color-success-dark)`,
              colorPrimary: `var(--color-success)`,
              // primaryColor: 'var(--color-text)',
              colorLink: 'var(--color-text)',
              colorTextDisabled: 'var(--color-text-disabled)',
              colorBgContainerDisabled: 'var(--color-bg-disabled)',
              borderColorDisabled: 'var(--color-bg-disabled)',
              textHoverBg: 'transparent',
              primaryShadow: 'none',
              defaultHoverBorderColor: `var(--color-grey-4)`,
              defaultHoverColor: `var(--color-text)`,
              controlHeight: 50,
              paddingBlock: 0,
            },
            Table: {
              headerBg: `var(--color-grey-2)`,
              headerColor: `var(--color-text-secondary)`,
              headerSplitColor: `transparent`,
              cellFontSize: 14,
              rowSelectedHoverBg: 'blue',
              colorBgContainer: `var(--color-grey-2)`,
              borderColor: 'var(--color-grey-6)',
              rowHoverBg: 'var(--color-grey-6)',
            },
            Pagination: {
              colorText: `var(--color-text)`,
              colorBgContainer: 'transparent',
              itemActiveBg: `var(--color-info)`,
            },
            Modal: {
              zIndexPopupBase: 1000,
              titleLineHeight: 2,
              contentBg: `var(--color-grey-2)`,
              footerBg: `var(--color-grey-2)`,
              headerBg: `var(--color-grey-2)`,
              colorPrimaryBorder: `var(--color-border-dark)`,
            },
            Spin: {
              colorBgBase: 'var(--color-text)',
            },
            Card: {
              actionsBg: `var(--color-grey-2)`,
              headerBg: `var(--color-grey-2)`,
              extraColor: `var(--color-grey-2)`,
              headerFontSize: 14,
              colorBgContainer: `var(--color-grey-1)`,
            },
            Radio: {
              colorPrimary: `var(--color-success)`,
              colorBgContainer: 'transparent',
              colorBorder: `var(--color-grey-6)`,
            },
            Dropdown: {
              colorBgElevated: `var(--color-grey-1)`,
            },
            Popover: {
              colorBgElevated: `var(--color-grey-dropdown)`,
              zIndexPopupBase: 10000,
            },
            Drawer: {
              zIndexPopupBase: 10001,
            },
            List: {
              colorSplit: `var(--color-border-dark)`,
            },
            Empty: {
              colorText: `var(--color-text) !important`,
            },
            Select: {
              colorText: `var(--color-text)`,
            },
            Input: {
              colorTextDescription: `var(--color-text)`,
            },
            Badge: {
              colorBorderBg: `#ff4d4f`,
              textFontSize: 10,
              indicatorHeight: 16,
            },
            FloatButton: {
              colorBgElevated: `var(--color-grey-4)`,
              colorPrimary: `var(--color-grey-4)`,
              colorPrimaryHover: `var(--color-grey-4)`,
            },
            Notification: {
              colorBgElevated: `var(--color-grey-4)`,
            },
            Alert: {
              colorText: `var(--color-grey-2)`,
            },
          },
        }}
      >
        <ReactQueryDevtools initialIsOpen={false} />
        <SocketProvider>
          <App />
        </SocketProvider>
      </ConfigProvider>
    </QueryClientProvider>
  </I18nextProvider>,
);
