export const triangleArrowRight = {
  name: 'triangleArrowRight',
  draw: (ctx, attrs, styles) => {
    const { x, y, width, height } = attrs;
    const { color } = styles;

    // Draw the triangle pointing to the right
    ctx.beginPath();
    ctx.moveTo(x - width / 2, y - height / 2); // Left point
    ctx.lineTo(x + width / 2, y); // Right point
    ctx.lineTo(x - width / 2, y + height / 2); // Bottom left point
    ctx.closePath();

    // Set the fill color and fill the triangle
    ctx.fillStyle = color;
    ctx.fill();
  },
  checkEventOn: (coordinate, attrs) => {
    const { x, y } = coordinate;
    const { width, height } = attrs;

    // Check if the point is inside the triangle
    const area = (width * height) / 2;
    const area1 = Math.abs((x * height) / 2);
    const area2 = Math.abs((y * width) / 2);
    return area1 + area2 <= area;
  },
};
